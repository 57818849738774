import { nodeServiceClient } from "~/lib/deps";

export default function () {
  const config = useRuntimeConfig();

  const antboxUrl = config.public.antboxUrl;
  const tenant = config.public.antboxTenant;

  if (!antboxUrl) {
    throw new Error("Could not find antbox url configuration");
  }

  const antboxClient = nodeServiceClient({ url: antboxUrl, tenant });

  return { antboxClient, tenant, antboxUrl };
}
